import React from 'react'
import {Link, graphql, useStaticQuery} from 'gatsby'
import headerStyles from './header.module.scss'
import pacmanBlue from '../resources/pacmanBlue.png'
import pacmanRed from '../resources/pacmanRed.png'
import ProjectsPage from '../pages/projects'

const Header = (props) =>{
    <link href="https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap" rel="stylesheet"></link>
    const data = useStaticQuery(graphql`
        query{
         site{
          siteMetadata{
            title
            
          }
        }
      }
    `)
    return(
        <header className={headerStyles.header}>
            {/* <h1>{data.site.siteMetadata.title}</h1> */}
            <nav className={headerStyles.nav}>
                <ul className ={headerStyles.navList}>
                    <li>
                        <Link className = {headerStyles.navItem} activeClassName = {headerStyles.activeNavItem} to ="/">Home</Link>
                    </li>
                    <li>
                        <Link className = {headerStyles.navItem} activeClassName = {headerStyles.activeNavItem} to ="/experience">Experience</Link>
                    </li>
                    <li>
                        <Link className = {headerStyles.navItem} activeClassName = {headerStyles.activeNavItem} to ="/projects">Projects</Link>
                    </li>
                    <li>
                        <Link className = {headerStyles.navItem} activeClassName = {headerStyles.activeNavItem} to ="/contact">Contact</Link>
                    </li>
                </ul>
            </nav>
                       
        </header>
    )
}

export default Header