import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import layoutStyles from './layout.module.scss'
const Layout = (props) =>{
    <link href="https://fonts.googleapis.com/css?family=Open+Sans|Press+Start+2P&display=swap&subset=cyrillic,greek" rel="stylesheet"></link>
    
    return(
        <div className = {layoutStyles.container}>
            <div className = {layoutStyles.content}>
                <Header/>
                {props.children}
            </div>
                      
            {/* //<Footer/> */}
        </div>
    )
}
export default Layout