import React from 'react'
import {graphql,useStaticQuery} from 'gatsby'
import Layout from '../components/layout'
import {Link} from 'gatsby'
import Head from '../components/head'

import projectStyles from './projects.module.scss'
const ProjectsPage = () =>{
    const data = useStaticQuery(graphql`
    query{
      allContentfulProjectPost(sort: {fields: projectDate, order: DESC}) {
          edges {
              node {
                  title
                  slug
                  projectDate(formatString: "MMM,YYYY")
                  
              }
          }
      }
  }
  `)
    return(
        <Layout>
            <Head title="Projects"/>
            <div className={projectStyles.mainContent}>
            <h2>My projects</h2>
            <ol className = {projectStyles.projects}>
                {data.allContentfulProjectPost.edges.map((edge)=>{
                        return(
                            <li className= {projectStyles.project}>
                              <Link to ={`/projects/${edge.node.slug}`}>
                                <h3>{edge.node.title}</h3>
                                <p>{edge.node.projectDate}</p>
                                </Link>
                            </li>
                        )
                })}
            </ol>
            </div>
            
            
        </Layout>
    )
}

export default ProjectsPage